<template>
  <div class="task-view">
    <Banner>
      <div class="banner-content">
        <MeepIconTask
          class="banner-content__icon banner-content__icon-stroke"
        />
        <span class="banner-content__text">{{ $t("menu.task") }}</span>
      </div>
    </Banner>
    <PageHeader :title="pageHeaderTilte" :has-back="true" @back="goBack" />
    <div class="task-view-content page-layout">
      <LoadingCard v-if="loading" />

      <!-- ## Les informations de l'entreprise ## -->
      <md-card class="meep-form" v-if="!loading">
        <md-card-content>
          <div class="meep-form-column meep-form-single-column">
            <md-checkbox
              v-model="newInfos.status"
              class="meep-form__checkbox-bold"
              @change="statusToggle"
            >
              {{ $t("tasks.view.check-text") }}
            </md-checkbox>
            <!-- Titre -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('title') }">
                <label>Titre *</label>

                <md-input
                  v-model="newInfos.title"
                  v-validate="'required'"
                  name="title"
                  type="text"
                />
                <span v-show="errors.has('title')" class="md-error">
                  {{ errors.first("title") }}
                </span>
              </md-field>
            </div>

            <!-- Description-->
            <div class="meep-textarea">
              <ckeditor
                v-model="newInfos.description"
                placeholder="Description"
                :editor="editor"
                :config="editorConfig"
                tag-name="textarea"
              />
            </div>

            <!-- Date Limite-->
            <div class="meep-input">
              <md-datepicker
                v-model="newInfos.deadline"
                :md-disabled-dates="isDateInvalid"
              >
                <label>Date Limite *</label>
              </md-datepicker>
            </div>

            <div class="meep-input">
              <md-field>
                <label>
                  {{ $t("form.company") }}
                </label>
                <md-select v-model="newInfos.company_id" name="collab">
                  <md-option
                    v-for="company in companies"
                    :key="company.id"
                    :value="company.id"
                  >
                    {{ company.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <div class="meep-input">
              <md-field>
                <label>Assigné à</label>
                <md-select v-model="newInfos.user_id" name="collab">
                  <md-option
                    v-for="user in users"
                    :key="user.id"
                    :value="user.id"
                  >
                    {{ user.firstname }} {{ user.lastname }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <div class="md-layout md-alignment-center-right">
              <md-button
                class="md-primary--inverted"
                @click="handleClick('delete')"
              >
                {{ $t("tasks.view.delete-text") }}
              </md-button>
              <md-button
                class="md-raised md-primary"
                @click="handleClick('save')"
              >
                {{ $t("tasks.view.confirm-text") }}
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>

    <confirm-action-modal
      v-if="isConfirmGlobalCancelModalOpen"
      :text="confirmGlobalCancelModalText"
      :object-to-act-upon="{}"
      @close="closeConfirmModal"
      @confirm="onConfirmModal"
    />
  </div>
</template>

<script>
/* Modèles */
import Banner from "@/components/Banner";
import MeepIconTask from "@/components/icons/MeepIconTask.vue";
import tasksModel from "../../model/tasks";
import usersModel from "../../model/users";
import companiesModel from "../../model/companies";
import PageHeader from "@/components/PageHeader";
import { mapGetters } from "vuex";
import LoadingCard from "@/components/LoadingCard";
import { MODALS, USERS } from "@/constants";
import { compareObjects, convertToTime, sortByKey } from "@/services/util";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import confirmActionModal from "@/components/modal/confirm-action";
import ForceLeaveMixin from "@/mixins/ForceLeaveMixin";
import _ from "lodash";
import CKEditorConfig from "@/mixins/CKEditorConfig";

export default {
  name: "ViewTask",

  components: {
    LoadingCard,
    PageHeader,
    Banner,
    MeepIconTask,
    confirmActionModal,
  },

  mixins: [ForceLeaveMixin, CKEditorConfig],

  data() {
    return {
      task: {},
      oldTask: {},
      newInfos: {},
      company: [],
      loading: false,
      ckeditor: CKEditor.component,
      allUsers: [],
      showTopButton: true,
      editor: ClassicEditor,
      companies: [],

      confirmationContext: "",
      isConfirmGlobalCancelModalOpen: false,
    };
  },

  watch: {
    $route: "fetchData",
  },

  created() {
    this.fetchData();
  },

  methods: {
    goBack() {
      window.history.back();
    },

    isDateInvalid(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },

    statusToggle() {
      this.oldTask.status = !this.oldTask.status;
      this.task.status = !this.task.status;
    },

    async fetchData() {
      try {
        const users = await usersModel.getUsersInOrganization();

        this.allUsers = users.filter(
          user => user.scope === USERS.ADMIN || user.scope === USERS.COLLAB
        );

        const companies = await companiesModel.getAll();

        this.companies = sortByKey(companies, "name");
        this.loading = true;

        this.oldTask = await tasksModel.get(this.$route.params.id);

        this.task = _.cloneDeep(this.oldTask);

        this.task.deadline = convertToTime(this.task.deadline).setHours(
          0,
          0,
          0,
          0
        );

        this.newInfos = { ...this.task };

        this.company = await companiesModel.get(this.task.company_id);

        this.loading = false;
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });

        this.loading = false;
      }
    },

    openConfirmModal() {
      this.isConfirmGlobalCancelModalOpen = true;
    },

    closeConfirmModal() {
      this.isConfirmGlobalCancelModalOpen = false;
    },

    async onDelete() {
      try {
        await tasksModel.remove(this.task.id);
        this.$toasted.global.AppSucces({
          message: this.$t("task.delete-success"),
        });
      } catch (error) {
        this.$toasted.global.AppError({
          message: error.msg,
        });
      }
    },

    async onSave() {
      const chosenDate = new Date(this.newInfos.deadline).setHours(
        new Date().getHours()
      );

      this.newInfos.deadline = new Date(chosenDate);

      try {
        await tasksModel.update(this.newInfos);
        this.$toasted.global.AppSucces({
          message: this.$t("task.valider-success"),
        });
        this.task = _.cloneDeep(this.newInfos);
      } catch (error) {
        this.$toasted.global.AppError({
          message: error.msg,
        });
      }
    },

    onConfirmModal() {
      switch (this.confirmationContext) {
        case MODALS.SAVE:
          this.onSave();
          this.$router.push("/dashboard/tasks/tab-all");
          break;
        case MODALS.DELETE:
          this.onDelete();
          this.forceLeave = true;
          this.$router.push("/dashboard/tasks/tab-all");
          break;
        default:
          this.forceRouteLeave();
      }
    },

    async handleClick(text) {
      switch (text) {
        case MODALS.DELETE:
          this.confirmationContext = MODALS.DELETE;
          this.openConfirmModal();
          break;
        case MODALS.SAVE:
          if (this.isInfosModified) {
            this.confirmationContext = MODALS.SAVE;
            this.openConfirmModal();
          } else {
            try {
              await tasksModel.update(this.oldTask);

              if (this.task.status) {
                this.$toasted.global.AppSucces({
                  message: this.$t("task.complete-check"),
                });
              } else {
                this.$toasted.global.AppSucces({
                  message: this.$t("task.complete-uncheck"),
                });
              }
              this.goBack();
            } catch (err) {
              this.$toasted.global.AppError({
                message: err.msg,
              });
            }
          }
          break;
      }
    },
  },

  computed: {
    ...mapGetters(["isAdmin", "isCollab", "isJEP"]),

    pageHeaderTilte() {
      return this.newInfos.title;
    },

    users() {
      return sortByKey(
        this.allUsers.filter(
          user =>
            user.scope === USERS.ADMIN ||
            user.scope === USERS.COLLAB ||
            user.company_id === this.task.company_id
        ),
        "firstname"
      );
    },

    isInfosModified() {
      return !compareObjects(this.task, this.newInfos);
    },

    confirmGlobalCancelModalText() {
      if (this.confirmationContext === MODALS.UNSAVE) {
        return {
          header: this.$t("tasks.view.unsaved-modal.header"),
          body: () => {
            return this.$t("tasks.view.unsaved-modal.body");
          },
          question: this.$t("tasks.view.unsaved-modal.question"),
        };
      } else if (this.confirmationContext === MODALS.SAVE) {
        return {
          header: this.$t("tasks.view.update-modal.header"),
          body: () => {
            return this.$t("tasks.view.update-modal.body");
          },
          question: this.$t("tasks.view.update-modal.question"),
        };
      } else
        return {
          header: this.$t("tasks.view.delete-modal.header"),
          body: () => {
            return this.$t("tasks.view.delete-modal.body");
          },
          question: this.$t("tasks.view.delete-modal.question"),
        };
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.forceLeave) {
      next();
    }

    if (this.isInfosModified) {
      this.confirmationContext = MODALS.UNSAVE;
      this.openConfirmModal();
      this.nextPath = to.fullPath;
      next(false);
    } else {
      next();
    }
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";
#app {
  .task-view {
    &__description {
      white-space: normal;
    }
    .page-header {
      .page-header__title{
        margin: 0;
      }
      .page-header__tabs-search {
        margin: 0;
      }
    }
    .md-card {
      margin-top: 30px;
      width: 770px;

      .meep-form__checkbox-bold {
        justify-content: center;
      }
    }

    &-content {
      height: 100%;
    }

    .ck-editor__top {
      display: block;
    }
  }
}
</style>
